<template>
<div>
  <div class="row justify-content-center">
    <div class="col-md-11 col-11" v-if="pdf == 0 && presu == 0">
        <!-- <ul class="nav nav-pills justify-content-left mt-3" id="tabsCapturaPop" role="tablist">
            <li class="nav-item" @click="cambiaTab(1)">
                <a class="nav-link active" data-toggle="pill" href="#pillsvigente" role="tab">
                <i v-if="tab==1" class="fas text-primary fa-circle animated slideInUp"></i>
                Vigente
                </a>
            </li>
            <li class="nav-item" @click="cambiaTab(2)">
                <a class="nav-link" data-toggle="pill" href="#pillsvigente" role="tab">
                <i v-if="tab==2" class="fas text-primary fa-circle animated slideInUp"></i>
                Histórico
                </a>
            </li>
        </ul> -->
        <div class="row">
            <div class="col-md-7 text-left mt-3" v-if="user.perfiles[0].usuario_perfil_id != 13">
                <button class="btn btn-secondary btn-block"  @click="reportExcel()">
                    <i class="far fa-download"></i>  Reporte Excel  <b-spinner small v-if="loader"></b-spinner>
                </button>
                <button class="btn btn-secondary ml-4"  @click="verPresup()" v-if="user.perfiles[0].usuario_perfil_id == 10">
                    <i class="far fa-chart-pie"></i> Presupuesto
                </button>
                <button class="btn btn-secondary ml-4"  @click="reportePdf()" v-if="user.perfiles[0].usuario_perfil_id != 13">
                    <i class="far fa-file-pdf"></i> Reporte PDF <b-spinner small v-if="loader3"></b-spinner>
                </button>
            </div>
            <div class="col-md-12">
                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
            </div>
            <div class="col-md-12" >
             <div class="row">                
                <div class="col-md-3 mt-1">
                    <label for="" class="text-secondary">IDPDV</label>
                    <input type="text" v-model="buscar" class="form-control input-nav-search" placeholder="Ingrese IDPDV" >
                </div>
                <div class="col-md-3 mt-1">
                    <label for="" class="text-secondary">Canales</label>
                    <select class="form-control" v-model="f_canal" >
                        <option value="" selected >Todos</option>
                        <option value="3" >Propio</option>
                        <option value="2" >Especialista</option>
                        <option value="1" >Retail</option>
                    </select>
                </div>
                <div class="col-md-3 mt-1">
                    <label for="" class="text-secondary">Regiones</label>
                    <select class="form-control" v-model="f_region">
                        <option value="" selected >Todos</option>
                        <option value="CENTRO" >CENTRO</option>
                        <option value="NORTE" >NORTE</option>
                        <option value="OCCIDENTE" >OCCIDENTE</option>
                        <option value="SUR" >SUR</option>
                    </select>
                </div>
                <div class="col-md-3 mt-1">
                    <label for="" class="text-secondary">Agencias</label>
                    <select class="form-control" v-model="f_agencia">
                        <option value="" selected >Todos</option>
                        <option value="JET" >JET</option>
                        <option value="SEED" >SEED</option>
                        <option value="BOX" >BOX</option> 
                    </select>
                </div>
                <div class="col-md-3 mt-1">
                    <label for="" class="text-secondary">Lider</label>
                    <b-form-input placeholder="Nombre de lider" type="text" v-model="f_lider"/>     
                </div>
                <div class="col-md-3 mt-1">
                     <label for="" class="text-secondary">Subdirector</label>
                    <b-form-input placeholder="Nombre de subdirector" type="text" v-model="f_subdirector"/>    
                </div>
                <div class="col-md-3 mt-1">
                     <label for="" class="text-secondary">Fecha inicial evento</label>
                    <b-form-input placeholder="Nombre de subdirector" type="date" v-model="f_inicio"/>    
                </div>
                <div class="col-md-3 mt-1">
                     <label for="" class="text-secondary">Fecha final evento</label>
                    <b-form-input placeholder="Nombre de subdirector" type="date" v-model="f_fin"/>    
                </div>
             </div>
            </div>
             <div class="col-md-12">
                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
            </div>
        </div>
            <!-- <div class="row  justify-content-center">
            <div class="col-md-6" v-for="list in listado" :key="list.id">
                    <v-card  class="mx-auto mt-5"  elevation="8">
                    <v-card-text>
                    <div class="container" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                            <div class="row justify-content-center">
                                <div class="col-md-12 mt-3">                                
                                    
                                    <v-stepper non-linear>
                                        <v-stepper-header>
                                            <v-stepper-step
                                            editable
                                            step="1"
                                            >
                                                Info de la tienda 
                                            </v-stepper-step>

                                            <v-divider></v-divider>

                                            <v-stepper-step
                                            editable
                                            step="2"
                                            >
                                            Info del evento
                                            </v-stepper-step>

                                            <v-divider></v-divider>

                                            <v-stepper-step
                                            step="3"
                                            editable
                                            >
                                            Info de agencia
                                            </v-stepper-step>
                                        </v-stepper-header>
                                        <v-stepper-items>
                                        <v-stepper-content step="1" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                    <h5 class="text-center">Información de la tienda</h5>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Folio</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">
                                                        <template v-if="list.canal == 1">
                                                                R_00{{list.id}}
                                                            </template>
                                                            <template v-else-if="list.canal == 2"> 
                                                                E_00{{list.id}}
                                                            </template>
                                                            <template v-else-if="list.canal == 3">
                                                                P_00{{list.id}}
                                                            </template>    
                                                        </p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Canal</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">
                                                            <template v-if="list.canal == 1">RETAIL</template>
                                                            <template v-else-if="list.canal == 2"> ESPECIALISTA</template>
                                                            <template v-else-if="list.canal == 3"> PROPIO</template>
                                                        </p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>IDPDV</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.idpdv}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Tienda</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.nombre_pdv}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Dirección</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.direccion}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Líder</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.mrt}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                <center>
                                                    <label for=""><b>Contacto</b></label>
                                                    <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.telefono}} </p>
                                                </center>
                                                </div>
                                                <div class="col-md-8 mt-1">
                                                <center>
                                                    <label for=""><b>Subdirector</b></label>
                                                    <p style="font-size:14px; color: #098FD6;" class="mt-2">{{list.subdirector}} </p>
                                                </center>
                                                </div>
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                            </div>
                                        </v-stepper-content>
                                        <v-stepper-content step="2" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                    <div class="col-md-7">
                                                        <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                    </div>
                                                    <div class="col-md-12 mt-3">
                                                        <h5 class="text-center">Información del evento</h5>
                                                    </div>
                                                    <div class="col-md-12 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Nombre:</b> <span style="color: #098FD6;"><br> {{list.evento}} </span></p>    
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Fecha inicial:</b> <span style="color: #098FD6;"><br> {{list.fecha_inicial}} </span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Fecha final:</b> <span style="color: #098FD6;"> <br> {{list.fecha_final}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Hora inicial:</b> <span style="color: #098FD6;"><br> {{list.hora_inicial}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Hora final:</b> <span style="color: #098FD6;"><br> {{list.hora_final}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-12 mt-3">
                                                        <center>
                                                            <p style="font-size:15px;" class="mt-1"><b>Costo:</b> <span style="color: #098FD6;"> ${{formatPrice(list.costo)}}</span></p>
                                                        </center>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                    </div>
                                            </div>
                                        </v-stepper-content>
                                        <v-stepper-content step="3" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                    <h5 class="text-center">Información de agencia</h5>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Nombre</b></label>
                                                        <p style="font-size:15px;" class="text-center mt-1"> <span style="color: #098FD6;"> {{list.agencia}}</span></p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Región</b></label>
                                                        <p style="font-size:15px;" class="text-center mt-1"> <span style="color: #098FD6;"> {{list.region}}</span></p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                <center>
                                                    <button class="btn btn-primary btn-sm"    @click="abrirDetalle(list)">
                                                        Ver servicios
                                                    </button>
                                                </center>
                                                </div>
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                            </div>
                                        </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </div>
                            <div class="col-md-11 mt-2">
                            <center>
                            <div class="table-responsive" v-if="user.perfiles[0].usuario_perfil_id != 13 "> 
                            <table class="table table-hover table-striped mt-3" >
                                <thead class="bg-secondary text-white">
                                <tr>
                                    <th scope="col">Validadores</th>
                                    <th scope="col">Acciones</th>
                                    <th scope="col" style="text-center">Estatus</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="user.perfiles[0].usuario_perfil_id == 2">
                                    <tr>
                                        <td>Subdirector</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-success'"  @click="autorizarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2" :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-danger'" @click="rechazarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.sub == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.sub == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Trade Marketing</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-success'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-danger'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.trade == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.trade == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.trade == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Agencia</td>
                                        <td>
                                        <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-success'" @click="finalizarAct(list)" :disabled="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"  :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-danger'" @click="autorizarAct3(list.id)" :disabled="list.agen != 0 || list.sub == 2 || list.trade == 2 ||  
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else-if="user.perfiles[0].usuario_perfil_id == 10">
                                    <tr>
                                        <td>Trade Marketing</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-success'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-danger'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Subdirector</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-success'"  @click="autorizarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2" :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-danger'" @click="rechazarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Agencia</td>
                                        <td>
                                        <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-success'" @click="finalizarAct(list)" :disabled="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"  :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-danger'" @click="autorizarAct3(list.id)" :disabled="list.agen != 0 || list.sub == 2 || list.trade == 2 ||  
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else-if="user.perfiles[0].usuario_perfil_id == 16">
                                    <tr>
                                        <td>Agencia</td>
                                        <td>
                                        <div class="d-flex">
                                            <template v-if="list.final == 0">
                                                <b-button   size="sm" class="ml-2"   :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-success'" @click="autorizarAct3(list.id)" :disabled="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"  :variant="list.agen != 0 || list.sub == 2  || list.trade == 2 ||
                                                user.perfiles[0].usuario_perfil_id != 16 ? 'outline-secondary' :'outline-danger'" @click="autorizarAct3(list.id)" :disabled="list.agen != 0 || list.sub == 2 || list.trade == 2 ||  
                                                user.perfiles[0].usuario_perfil_id != 16">
                                                    Rechazar
                                                </b-button>
                                            </template>
                                            <template v-else-if="list.final == 1">
                                                <b-button   size="sm" class="ml-2"   :variant="list.testigo == 1 ? 'outline-secondary' :'outline-primary'" @click="finalizarAct(list)" :disabled="list.testigo == 1">
                                                    Subir Testigos
                                                </b-button>
                                            </template>
                                            </div>
                                        </td>
                                        <td>
                                        <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Trade Marketing</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-success'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2"   :variant="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10 ? 'outline-secondary' : 'outline-danger'" @click="autorizarAct2(list.id)" :disabled="list.trade != 0 || list.sub == 2 || 
                                                user.perfiles[0].usuario_perfil_id != 10">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Subdirector</td>
                                        <td>
                                            <div class="d-flex">
                                                <b-button   size="sm" class="ml-2"   :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-success'"  @click="autorizarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Autorizar
                                                </b-button> &nbsp;&nbsp;
                                                <b-button   size="sm" class="ml-2" :variant="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2 ? 'outline-secondary' : 'outline-danger'" @click="rechazarAct1(list.id)" :disabled="list.sub != 0 || 
                                                user.perfiles[0].usuario_perfil_id != 2">
                                                    Rechazar
                                                </b-button>
                                            </div>
                                        </td>
                                        <td>
                                        <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 0">
                                                Pendiente
                                            </v-btn>
                                            <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 2">
                                                Cancelado
                                            </v-btn>
                                            <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 1">
                                                Autorizado
                                            </v-btn>
                                        </td>
                                    </tr>                   
                                </template>
                            </tbody>
                            </table>
                        </div>
                                <template v-if="user.perfiles[0].usuario_perfil_id != 13">
                                    <u style="cursor: pointer; color:#E65252;" @click="abrirImprimir(list)">  <i class="far fa-download"></i> Descargar PDF</u>  
                                </template>
                                <template v-if="user.perfiles[0].usuario_perfil_id == 13">
                                    <button :class="list.final == 0 ? 'btn btn-primary ml-3' :'btn btn-light ml-3'" :disabled="list.final != 0"  @click="reenviarCo(list.id)">
                                    Reenviar solicitud
                                    </button>
                                </template>
                                <template v-if="user.perfiles[0].usuario_perfil_id == 2 || user.perfiles[0].usuario_perfil_id == 10">
                                    <button :class="list.final == 0 ? 'btn btn-primary ml-3' :'btn btn-light ml-3'" :disabled="list.final != 0"  @click="$router.push('/registro/edit/' + list.id)">
                                    Editar solicitud
                                    </button>
                                </template>
                                <br>
                            </center>
                            </div>
                        </div>
                        <br>
                    </div>
                    </v-card-text>
                </v-card>
            </div>
            </div> -->
        

        <div class="table-responsive">
            <table class="table table-hover table-striped mt-3" >
                <thead class="bg-primary text-white">
                <tr>
                    <!-- <th scope="col"></th> -->
                    <th scope="col">Folio</th>
                    <!-- <th scope="col">Canal</th> -->
                    <!-- <th scope="col">Región</th> -->
                    <th scope="col">IDPDV</th>
                    <th scope="col">Tienda</th>
                    <!-- <th scope="col">Dirección</th> -->
                    <th  scope="col" >Tipo de evento</th>
                    <th scope="col">Inicio</th>
                    <th scope="col">Final</th>
                    <th scope="col">Costo</th>
                    <th scope="col">Detalles</th>
                    <th scope="col" class="text-center">
                        Subdirector
                        <select class="form-control" v-model="est_sub"  :disabled="user.perfiles[0].usuario_perfil_id != 2" >
                            <option value="" selected >Todos</option>
                            <option value="0" >Pendiente</option>
                            <option value="2" >Cancelado</option>
                            <option value="1" >Autorizado</option>
                        </select>
                    </th>
                    <th scope="col" class="text-center">
                        Trade Mkt
                        <select class="form-control" v-model="est_emb" :disabled="user.perfiles[0].usuario_perfil_id != 10">
                            <option value="" selected >Todos</option>
                            <option value="0" >Pendiente</option>
                            <option value="2" >Cancelado</option>
                            <option value="1" >Autorizado</option>
                        </select>
                    </th>
                    <th scope="col" class="text-center">
                        Agencia
                        <select class="form-control" v-model="est_age"  :disabled="user.perfiles[0].usuario_perfil_id != 16">
                            <option value="" selected >Todos</option>
                            <option value="0" >Pendiente</option>
                            <option value="2" >Cancelado</option>
                            <option value="1" >Autorizado</option>
                        </select>    
                    </th>
                    <!-- <th scope="col"  v-if="user.perfiles[0].usuario_perfil_id == 13"></th>
                    <th scope="col" v-if="user.perfiles[0].usuario_perfil_id == 2  || user.perfiles[0].usuario_perfil_id == 10"></th> -->
                    <th scope="col"> </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="list in listado" :key="list.id">
                    <td >
                      {{list.folio}}
                    </td>
                    <!-- <td>
                        <template v-if="list.canal == 1">RETAIL</template>
                        <template v-else-if="list.canal == 2"> ESPECIALISTA</template>
                        <template v-else-if="list.canal == 3"> PROPIO</template>
                    </td> -->
                    <!-- <td>{{list.region}}</td> -->
                    <td>{{list.idpdv}}</td>
                    <td>{{list.nombre_pdv}}</td>
                    <!-- <td>{{list.direccion}}</td> -->
                    <td>
                        {{list.evento}}
                        <!-- <div style="background-color: #83c32d; border-radius: 10px; color:#000; border-color:#83c32d;" :class="isMobile ? 'flexbox2' : 'flexbox'">
                                <span style="font-size:14px;" class="text-center">
                                    <b>{{list.evento}}</b>
                                    <br>
                                     Fecha inicio: {{list.fecha_inicial}} {{list.hora_inicial}}
                                     <br>
                                     Fecha final: {{list.fecha_final}} {{list.hora_final}}
                                </span>
                        </div>                          -->
                    </td>
                    <td>{{list.fecha_inicial}} {{list.hora_inicial}}</td>
                    <td>{{list.fecha_final}} {{list.hora_final}}</td>
                    <td>${{formatPrice(list.costo)}}</td>
                    <td>
                        <div class="row justify-content-center">
                            <div class="col-md-6 col-6">
                                <u style="cursor: pointer; color:#3ABF30;" @click="abrirDetalle(list)"> Ver</u>  
                            </div>
                            <div class="col-md-6 col-6" v-if="user.perfiles[0].usuario_perfil_id != 13">
                                <u style="cursor: pointer; color:#E65252;" @click="abrirImprimir(list)">  <i class="far fa-download"></i></u>  
                            </div>
                        </div>
                      
                    </td>
                    <td>
                     <center>
                        <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.sub == 0">
                            <b>Pendiente</b>
                        </v-btn>
                        <v-btn  color="#E65252" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.sub == 2">
                            <b>Cancelado</b>
                        </v-btn>
                        <v-btn  color="#3ABF30" elevation="11"  raised small  style="text-transform: none; color:#fff;" v-if="list.sub == 1">
                            <b>Autorizado</b> 
                        </v-btn>             
                      </center>
                    </td>
                    <td>
                     <center>
                        <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 0">
                            <b>Pendiente</b>
                        </v-btn>
                        <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 2">
                            <b>Cancelado</b>
                        </v-btn>
                        <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.trade == 1">
                            <b>Autorizado</b> 
                        </v-btn>
                     </center>
                    </td>
                    <td>
                      <center>
                        <v-btn  color="#E8C100" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 0">
                            <b>Pendiente</b>
                        </v-btn>
                        <v-btn  color="#E65252" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 2">
                            <b>Cancelado</b>
                        </v-btn>
                        <v-btn  color="#3ABF30" elevation="11"  raised small style="text-transform: none; color:#fff;" v-if="list.agen == 1">
                           <b>Autorizado</b> 
                        </v-btn>    
                      </center>
                    </td>
                    <td>
                        <div class="d-flex"  v-if="user.perfiles[0].usuario_perfil_id == 2 && list.sub == 0">                  
                            <b-button   size="sm" class="ml-2"  variant="success"  @click="autorizarAct1(list.id)" title="Autorizar">
                              <i class="far fa-check"></i>
                            </b-button>
                            <b-button   size="sm" class="ml-2" variant="danger" @click="rechazarAct1(list.id)" title="Rechazar">
                              <i class="fas fa-trash"></i>
                            </b-button>
                            <button :class="list.final == 0 ? 'btn btn-primary btn-sm  ml-2' :'btn btn-light btn-sm  ml-2'" :disabled="list.final != 0"  @click="$router.push('/registro/edit/' + list.id)">
                               Editar 
                            </button>
                        </div>
                        <div class="d-flex" v-if="user.perfiles[0].usuario_perfil_id == 10 && list.trade == 0">
                            <b-button   size="sm" class="ml-2" variant="success" @click="autorizarAct2(list.id)"  title="Autorizar" :disabled="list.sub == 0">
                               <i class="far fa-check"></i>
                            </b-button>
                            <b-button   size="sm" class="ml-2" variant="danger" @click="rechazarAct2(list.id)" title="Rechazar" :disabled="list.sub == 0">
                                <i class="fas fa-trash"></i>
                            </b-button>
                            <button :class="list.final == 0 ? 'btn btn-primary btn-sm  ml-2' :'btn btn-light btn-sm  ml-2'" :disabled="list.final != 0"  @click="$router.push('/registro/edit/' + list.id)">
                               Editar 
                            </button>
                        </div>
                        <div class="d-flex"  v-if="user.perfiles[0].usuario_perfil_id == 16">
                            <template v-if="list.final == 0">
                                <b-button   size="sm" class="ml-2" variant="success" @click="autorizarAct3(list.id)"  title="Autorizar"  :disabled="list.trade == 0">
                                   <i class="far fa-check"></i>
                                </b-button>
                                <b-button   size="sm" class="ml-2" variant="danger" @click="modalPorque(list.id)" title="Rechazar"  :disabled="list.trade == 0">
                                    <i class="fas fa-trash"></i>
                                </b-button>
                            </template>
                            <template v-else-if="list.final == 1 && list.testigo == 0">
                                <b-button   size="sm" class="ml-2" variant="outline-primary" @click="finalizarAct(list)" >
                                    Testigos
                                </b-button>
                            </template>
                        </div>
                        <div class="d-flex"  v-if="user.perfiles[0].usuario_perfil_id == 13">
                           <button :class="list.final == 0 ? 'btn btn-primary btn-sm' :'btn btn-light btn-sm'" :disabled="list.final != 0"  @click="reenviarCo(list.id)">
                              Reenviar 
                           </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12" v-if="listado.length == 0" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
            <center>
                <br>
               <h4 style="color:#098FD6; marg">Nada que mostrar.</h4> 
                <div class="col-md-4" v-if="listado.length == 0">
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                </div>
            </center>
        </div>
        <br><br><br><br><br>
    </div>
    <div class="col-md-11 col-11 mt-2" v-else-if="pdf == 1 && presu == 0">
      <u style="cursor: pointer; color:#3ABF30;" @click="pdf = 0"> Regresar</u>  
      <!-- <u class="ml-5" style="cursor: pointer; color:#e17680;" @click="generaReport()"> Descargar</u>   -->
       <hr>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="900"
            :pdf-quality="2"
            pdf-format="a4"
            pdf-orientation="portrait"
            :manual-pagination="true"
            ref="html2Pdf"
            margin="0"
            :filename="obj.id + '-' + obj.evento + '-' + $getDate() + '.pdf'"
        >
            <section slot="pdf-content" >
            <center>
                    <img src="fondo_pdf.jpg" alt="" width="860" >
             </center>
             <!-- <div  class="html2pdf__page-break"/> -->
             <div class="container">
                <br><br>
                <div class="row justify-content-center">
                    <div class="col-md-5">
                        <p style="font-size:14px;"><b>Activación</b>:
                           {{obj.folio}}
                        </p>
                        <p style="font-size:14px;"><b>Encargado</b>: {{obj.mrt}}</p>
                        <p style="font-size:14px;"><b>Teléfono de contacto</b>: {{obj.telefono}}</p>
                        <p style="font-size:14px;"><b>Subdirector:</b> {{obj.subdirector}}</p>
                        <p style="font-size:14px;"><b>Canal:</b> 
                            <template v-if="obj.canal == 1">
                                RETAIL
                            </template>
                            <template v-else-if="obj.canal == 2"> 
                                ESPECIALISTA
                            </template>
                            <template v-else-if="obj.canal == 3">
                                PROPIO
                            </template> 
                        </p>
                        <p style="font-size:14px;"><b>Tienda:</b> {{obj.idpdv}} - {{obj.nombre_pdv}}</p>
                        <p style="font-size:14px;"><b>Dirección:</b> {{obj.calle}} {{obj.numero}} {{obj.colonia}} C.P {{obj.cp}} {{obj.estado}} {{obj.ciudad}}</p>
                    </div>
                    <div class="col-md-5">
                        <p style="font-size:14px;"><b>Tipo de evento</b>: {{obj.evento}}</p>
                        <p style="font-size:14px;"><b>Fecha inicio</b>: {{obj.fecha_inicial}} {{obj.hora_inicial}}</p>
                        <p style="font-size:14px;"><b>Fecha finalización:</b> {{obj.fecha_final}} {{obj.hora_final}}</p>
                        <p style="font-size:14px;"><b>Agencia:</b> {{obj.agencia}}</p>
                        <p style="font-size:14px;"><b>Región:</b> {{obj.region}}</p>
                        <p style="font-size:14px;"><b>Tipo de producto:</b> {{obj.tipo_producto}}</p>
                        <p style="font-size:14px;"><b>Cálculo alta compromiso:</b> {{formatPrice(obj.altas_compromiso)}}</p>
                    </div>
                    <!-- <div class="col-md-12 mt-1">
                        <h5 class="text-center">Información del evento</h5>
                    </div>
                    <div class="col-md-4 mt-1">
                        <p style="font-size:15px;"><strong>Nombre:</strong> {{obj.evento}}</p>    
                    </div>
                    <div class="col-md-4 mt-3">
                        <p style="font-size:15px;"><strong>Fecha inicial:</strong> {{obj.fecha_inicial}} {{obj.hora_inicial}}</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <p style="font-size:15px;"><strong>Fecha final:</strong> {{obj.fecha_final}} {{obj.hora_final}}</p>
                    </div>
                    <div class="col-md-12 mt-3">
                        <hr>
                        <h5 class="text-center">Información de agencia</h5>
                    </div>
                    <div class="col-md-4 mt-3">
                        <p style="font-size:15px;" class="text-center"><strong>Nombre:</strong> {{obj.agencia}}</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <p style="font-size:15px;" class="text-center"><strong>Región:</strong> {{obj.region}}</p>
                    </div> -->
            </div> 
                <br>
                <b-alert variant="success"   show>
                <h5 class="text-center">Servicios</h5> 
                </b-alert>
                <table class="table table-hover table-striped" style="margin-top: -16px;">
                        <thead class="bg-secondary text-white " style="background: #d6d6d6;">
                        <tr>
                            <th scope="col">Servicio</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Hora inicial</th>
                            <th scope="col">Horas final</th>
                            <th scope="col">Costo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="det in Detalle" :key="det.id">
                            <td>{{det.servicio}}</td>
                            <td>{{det.fecha}}</td>
                            <td>{{det.hora_inicio}}</td>
                            <td>{{det.hora_fin}}</td>
                            <td>${{formatPrice(det.costo)}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br>
                    <!-- <b-alert variant="success"   show>
                       <h5 class="text-center">Costos</h5> 
                    </b-alert>
                    <table class="table table-hover table-striped" style="margin-top: -16px;">
                        <thead class="bg-secondary text-white " style="background: #d6d6d6;">
                        <tr>
                            <th scope="col">Presupuesto inicial</th>
                            <th scope="col">Saldo restante</th>
                            <th scope="col">Costo de activación</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>${{formatPrice(preImp)}}</td>
                            <td>${{formatPrice(totalImp)}}</td>
                            <td>${{formatPrice(gastado)}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br> -->
                    <b-alert variant="success"   show>
                       <h5 class="text-center">Movimientos</h5> 
                    </b-alert>
                    <table class="table table-hover table-striped" style="margin-top: -16px;">
                        <thead class="bg-secondary text-white " style="background: #d6d6d6;">
                        <tr>
                            <th scope="col">Perfil</th>
                            <th scope="col">Estatus</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Supervisor</td>
                                <td>
                                    <p style="font-size:17px; color:#e8c100;"  v-if="obj.sub == 0">Pendiente</p>
                                    <p style="font-size:17px; color:#e17680;"  v-if="obj.sub == 2">Rechazado</p>
                                    <p style="font-size:17px; color:#3ABF30;"  v-if="obj.sub == 1">Autorizado</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Trade Marketing</td>
                                <td>
                                    <p style="font-size:17px; color:#e8c100;"  v-if="obj.trade == 0">Pendiente</p>
                                    <p style="font-size:17px; color:#e17680;"  v-if="obj.trade == 2">Rechazado</p>
                                    <p style="font-size:17px; color:#3ABF30;"  v-if="obj.trade == 1">Autorizado</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Agencia</td>
                                <td>
                                    <p style="font-size:17px; color:#e8c100;"  v-if="obj.agen == 0">Pendiente</p>
                                    <p style="font-size:17px; color:#e17680;"  v-if="obj.agen == 2">Rechazado</p>
                                    <p style="font-size:17px; color:#3ABF30;"  v-if="obj.agen == 1">Autorizado</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br><br>
                    <div  class="html2pdf__page-break"/>
                    <br><br>
                    <b-alert variant="success"   show>
                    <h5 class="text-center">Evidencias</h5> 
                    </b-alert>
                    <div class="row">
                        <div class="col-md-12" v-for="det in Detalle" :key="det.id">
                            <center>
                                <br>
                                <h6>{{det.servicio}}</h6>
                                <br>
                            </center>
                          <div class="row">
                            <div class="col-md-6">
                                <p>Testigo Check in</p>
                                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                <b-img  thumbnail fluid :src="$api.baseUriFiles+'activaciones/'+det.checkin"></b-img>
                                <br>
                            </div>
                            <div class="col-md-6">
                                <p>Testigo en actividad</p>
                                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                <b-img  thumbnail fluid :src="$api.baseUriFiles+'activaciones/'+det.actividad"></b-img>
                                <br>
                            </div>
                          </div>
                            
                        </div>
                    </div>
                    <br><br><br><br>
                </div>
            </section>
        </vue-html2pdf>
    </div>
    
    <div class="col-md-11 col-11 mt-2" v-if="presu == 1" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
        <div class="row">
            <div class="col-md-3 text-left mt-3"></div>
            <div class="col-md-9 text-right mt-3" v-if="user.perfiles[0].usuario_perfil_id != 13">
                <button class="btn btn-primary ml-4"  @click="presu = 0" v-if="user.perfiles[0].usuario_perfil_id == 10 || user.perfiles[0].usuario_perfil_id == 2">
                    <!-- <i class="far fa-chart-pie"></i> -->
                    <i class="far fa-arrow-left"></i> Regresar
                    <!-- <i class="far fa-download"></i>  Presupuesto -->
                </button>
            </div>
            <div class="col-md-4 mt-1">
                <label for="" class="text-secondary">Regiones</label>
                <select class="form-control" v-model="f_region" @change="verPresup()">
                    <option value="" selected >Todos</option>
                    <option value="CENTRO" >CENTRO</option>
                    <option value="NORTE" >NORTE</option>
                    <option value="OCCIDENTE" >OCCIDENTE</option>
                    <option value="SUR" >SUR</option>
                </select>
            </div>
            <div class="col-md-4 mt-1">
                <label for="" class="text-secondary">Agencias</label>
                <select class="form-control" v-model="f_agencia" @change="verPresup()">
                    <option value="" selected >Todos</option>
                    <option value="JET" >JET</option>
                    <option value="SEED" >SEED</option>
                    <option value="BOX" >BOX</option> 
                </select>
            </div>
            <!-- <div class="col-md-2 mt-1">
                <br>
                <button class="btn btn-primary ml-4" @click="verPresup()">
                    Filtrar
                </button>
            </div> -->
            <div class="col-md-12">
                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
            </div>
            <div class="col-md-3 mt-5"  v-if="agenciaGra == 'JET' || !agenciaGra">
              <v-alert dense  color="#E9426D" dark>
                <center>
                    AGENCIA JET
                </center>
              </v-alert>
              <v-card elevation="3" outlined  style="margin-top: -15px;" >
                <v-card-text>
                  <h5 class="text-center">Presupuesto $ {{formatPrice(pre1)}}</h5>
                    <div id="chart">
                        <apexchart type="bar" height="980" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card-text>
               </v-card>
            </div>
            <div class="col-md-3 mt-5"  v-if="agenciaGra == 'SEED' || !agenciaGra">
             <v-alert dense  color="#EC6839" dark>
                <center>
                    AGENCIA SEED
                </center>
              </v-alert>
             <v-card elevation="3" outlined  style="margin-top: -15px;" >
                <v-card-text>
                  <h5 class="text-center">Presupuesto $ {{formatPrice(pre2)}}</h5>
                   <div id="chart">
                     <apexchart type="bar" height="980" :options="chartOptions2" :series="series2"></apexchart>
                    </div>
                </v-card-text>
             </v-card>
            </div>
            <div class="col-md-3 mt-5" v-if="agenciaGra == 'BOX' || !agenciaGra">
              <v-alert dense  color="#954B97" dark >
                <center>
                    AGENCIA BOX
                </center>
              </v-alert>
              <v-card elevation="3" outlined   style="margin-top: -15px;" >
                <v-card-text>
                    <h5 class="text-center">Presupuesto $ {{formatPrice(pre3)}}</h5>
                    <div id="chart">
                      <apexchart type="bar" height="980" :options="chartOptions3" :series="series3"></apexchart>
                    </div>
                </v-card-text>
              </v-card>
            </div> 
            <div class="col-md-12">
                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
            </div>
            <!-- <div class="col-md-1 text-center mt-2">
                <br>
                <u :style="pregion == 'CENTRO' ? 'color:#FF04A7; cursor: pointer;' : 'color:#390076; cursor: pointer;'" @click="cambiaRegion('CENTRO')">CENTRO</u>
            </div>
            <div class="col-md-1 text-center mt-2">
                <br>
                <u :style="pregion == 'NORTE' ? 'color:#FF04A7; cursor: pointer;' : 'color:#390076; cursor: pointer;'" @click="cambiaRegion('NORTE')">NORTE</u>
            </div>
            <div class="col-md-1 text-center mt-2">
                <br>
                <u :style="pregion == 'OCCIDENTE' ? 'color:#FF04A7; cursor: pointer;' : 'color:#390076; cursor: pointer;'" @click="cambiaRegion('OCCIDENTE')">OCCIDENTE</u>
            </div>
            <div class="col-md-1 text-center mt-2">
                <br>
                <u :style="pregion == 'SUR' ? 'color:#FF04A7; cursor: pointer;' : 'color:#390076; cursor: pointer;'" @click="cambiaRegion('SUR')">SUR</u>
            </div>
            <div class="col-md-8"></div> -->
            <div class="col-md-7 mt-5">
               <br>
               <v-alert dense  color="#00a1f8" dark>
                <center>
                   PRESUPUESTO AGENCIA POR REGIÓN 
                </center>
               </v-alert>
               <v-card elevation="3" outlined   style="margin-top: -15px;">
                <v-card-text>
                  <div class="table-responsive">
                    <table class="table mt-4 " >
                        <thead >
                        <tr>
                            <th scope="col">Agencia</th>
                            <th scope="col">Región</th>
                            <th scope="col">Presupuesto</th>
                            <th scope="col">Debitado</th>
                            <th scope="col">Disponible</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="det in listfilt" :key="det.id" >
                            <td>
                               {{det.agencia}}
                            </td>
                            <td>{{det.region}}</td>
                            <td>
                               ${{formatPrice(det.presupuesto)}}
                            </td>
                            <td>
                               ${{formatPrice(det.presupuesto - det.total)}}
                            </td>
                            <td>
                              ${{formatPrice(det.total)}}
                            </td>
                            <!-- <td>
                              <template v-if="det.agencia == 'CEREZO MKT'">
                                <v-btn  color="#288B3D" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                              </template>
                              <template v-else-if="det.agencia == 'RDE PROMOCIONES'">
                                <v-btn  color="#284068" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                              </template>
                              <template v-else-if="det.agencia == 'SE-ED WORKING'">
                                <v-btn  color="#7A580B" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                              </template>
                              <template v-else-if="det.agencia == 'BOX HEADE'">
                                <v-btn  color="#07A49F" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                              </template>
                            </td> -->
                        </tr>
                        </tbody>
                    </table>
                  </div>
                </v-card-text>
               </v-card>
               <br><br><br><br><br>
            </div>
            <div class="col-md-5 mt-5">
               <br>
               <v-alert dense  color="#00a1f8" dark>
                <center>
                    PRESUPUESTO TOTAL REGIÓN
                </center>
               </v-alert>
               <v-card elevation="3" outlined   style="margin-top: -15px;">
                <v-card-text>
                    <div id="chart">
                      <apexchart type="bar" height="980" :options="chartOptions5"></apexchart>
                    </div>
                    <div class="table-responsive">
                    <table class="table mt-4 " >
                        <thead >
                        <tr>
                            <th scope="col">Región</th>
                            <th scope="col">Presupuesto</th>
                            <!-- <th scope="col">Color</th> -->
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-if="regionGra == 'CENTRO' || !regionGra">
                               <td>CENTRO</td>
                               <td>${{formatPrice(centro)}}</td>
                               <!-- <td>
                                <v-btn  color="#626F42" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                               </td> -->
                            </tr>
                            <tr v-if="regionGra == 'OCCIDENTE' || !regionGra">
                               <td>OCCIDENTE</td>
                               <td>${{formatPrice(occidente)}}</td>
                               <!-- <td>
                                 <v-btn  color="#1E546E" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                               </td> -->
                            </tr>
                            <tr v-if="regionGra == 'NORTE' || !regionGra">
                               <td>NORTE</td>
                               <td>${{formatPrice(norte)}}</td>
                               <!-- <td>
                                 <v-btn  color="#6F4C42" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                               </td> -->
                            </tr>
                            <tr v-if="regionGra == 'SUR' || !regionGra">
                               <td>SUR</td>
                               <td>${{formatPrice(sur)}}</td>
                               <!-- <td>
                                 <v-btn  color="#426B6F" elevation="11"  raised small style="text-transform: none; color:#fff;">
                             
                                </v-btn>
                               </td> -->
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </v-card-text>
               </v-card>
               <br><br><br><br><br>
            </div>
        </div>
    </div>
   
  </div>
  

    <b-modal id="detalleAgen" :title="'Información Activación ' + ' - Folio:' + obj.folio " hide-footer size="xl" header-close-content="Cerrar"> 
            <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-11" >
                    <v-card  class="mx-auto"  elevation="8">
                    <v-card-text>
                    <div class="container" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                      <div class="row justify-content-center">
                         <div class="col-md-12 mt-2 ">                                
                                    <v-stepper non-linear>
                                        <v-stepper-header>
                                            <v-stepper-step
                                            editable
                                            step="1"
                                            >
                                                Info de la tienda 
                                            </v-stepper-step>

                                            <v-divider></v-divider>

                                            <v-stepper-step
                                            editable
                                            step="2"
                                            >
                                            Info del evento
                                            </v-stepper-step>

                                            <v-divider></v-divider>

                                            <v-stepper-step
                                            step="3"
                                            editable
                                            >
                                            Info de agencia
                                            </v-stepper-step>
                                        </v-stepper-header>
                                        <v-stepper-items>
                                        <v-stepper-content step="1" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                    <h5 class="text-center">Información de la tienda</h5>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Folio</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">
                                                           {{obj.folio}}
                                                        </p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Canal</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">
                                                            <template v-if="obj.canal == 1">RETAIL</template>
                                                            <template v-else-if="obj.canal == 2"> ESPECIALISTA</template>
                                                            <template v-else-if="obj.canal == 3"> PROPIO</template>
                                                        </p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>IDPDV</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.idpdv}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Tienda</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.nombre_pdv}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Región</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.region}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Dirección</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">
                                                             {{obj.calle}} {{obj.numero}} {{obj.colonia}} C.P {{obj.cp}} {{obj.estado}} {{obj.ciudad}}
                                                        </p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                    <center>
                                                        <label for=""><b>Líder</b></label>
                                                        <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.mrt}}</p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-1">
                                                <center>
                                                    <label for=""><b>Contacto</b></label>
                                                    <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.telefono}} </p>
                                                </center>
                                                </div>
                                                <div class="col-md-8 mt-1">
                                                <center>
                                                    <label for=""><b>Subdirector</b></label>
                                                    <p style="font-size:14px; color: #098FD6;" class="mt-2">{{obj.subdirector}} </p>
                                                </center>
                                                </div>
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                            </div>
                                        </v-stepper-content>
                                        <v-stepper-content step="2" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                    <div class="col-md-7">
                                                        <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                    </div>
                                                    <div class="col-md-12 mt-3">
                                                        <h5 class="text-center">Información del evento</h5>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Nombre:</b> <span style="color: #098FD6;"><br> {{obj.evento}} </span></p>    
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Región:</b> <span style="color: #098FD6;"><br> {{obj.region}} </span></p>    
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Fecha inicial:</b> <span style="color: #098FD6;"><br> {{obj.fecha_inicial}} </span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Fecha final:</b> <span style="color: #098FD6;"> <br> {{obj.fecha_final}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Hora inicial:</b> <span style="color: #098FD6;"><br> {{obj.hora_inicial}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-5 mt-3">
                                                    <center>
                                                        <p style="font-size:15px;" class="mt-1"><b>Hora final:</b> <span style="color: #098FD6;"><br> {{obj.hora_final}}</span></p>
                                                    </center>
                                                    </div>
                                                    <div class="col-md-4 mt-3">
                                                        <center>
                                                            <p style="font-size:15px;" class="mt-1"><b>Costo:</b> <br> <span style="color: #098FD6;"> ${{formatPrice(obj.costo)}}</span></p>
                                                        </center>
                                                    </div>
                                                    <div class="col-md-4 mt-3">
                                                        <center>
                                                            <p style="font-size:15px;" class="mt-1"><b>Tipo de producto:</b> <br> <span style="color: #098FD6;"> {{obj.tipo_producto}}</span></p>
                                                        </center>
                                                    </div>
                                                    <div class="col-md-4 mt-3">
                                                        <center>
                                                            <p style="font-size:15px;" class="mt-1"><b>Cálculo alta compromiso:</b> <br> <span style="color: #098FD6;"> {{obj.altas_compromiso}}</span></p>
                                                        </center>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                    </div>
                                                    <div class="col-md-8 mt-3" v-if="obj.motivo">
                                                        <center>
                                                            <p style="font-size:15px;" class="mt-1"><b>Motivo de cancelación:</b> <br> <span style="color: #098FD6;"> {{obj.motivo}}</span></p>
                                                        </center>
                                                    </div>
                                            </div>
                                        </v-stepper-content>
                                        <v-stepper-content step="3" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                            <div class="row justify-content-center">
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                    <h5 class="text-center">Información de agencia</h5>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Nombre</b></label>
                                                        <p style="font-size:15px;" class="text-center mt-1"> <span style="color: #098FD6;"> {{obj.agencia}}</span></p>
                                                    </center>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <center>
                                                        <label for=""><b>Región</b></label>
                                                        <p style="font-size:15px;" class="text-center mt-1"> <span style="color: #098FD6;"> {{obj.region}}</span></p>
                                                    </center>
                                                </div>
                                                <div class="col-md-12 mt-3">
                                                <center>
                                                    <div class="table-responsive">
                                                        <table class="table table-hover table-striped mt-3" >
                                                            <thead class="bg-secondary text-white">
                                                            <tr>
                                                                <th scope="col">Servicio</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Hora incial</th>
                                                                <th scope="col">Hora final</th>
                                                                <th scope="col">Permitido</th>
                                                                <th scope="col" style="text-center">Costo</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="det in arrayDetalle" :key="det.id">
                                                                <td>{{det.servicio}}</td>
                                                                <td>{{det.fecha}}</td>
                                                                <td>{{det.hora_inicio}}</td>
                                                                <td>{{det.hora_fin}}</td>
                                                                <td>{{det.permitido}}</td>
                                                                <td>${{formatPrice(det.costo)}}</td>
                                                                <td>
                                                                    <template v-if="det.checkin && det.actividad">
                                                                        <button   class="btn btn-primary btn-sm " type="button" @click="verTestigo(det)">
                                                                          Ver testigos
                                                                        </button>
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><strong>Total</strong></td>
                                                                <td> ${{formatPrice(totalNeto)}}</td>
                                                                <td></td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </center>
                                                </div>
                                                <div class="col-md-7">
                                                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                                                </div>
                                            </div>
                                        </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                            </div>
                        </div>
                        <br>
                    </div>
                    </v-card-text>
                </v-card>
            </div>

            <!-- <div class="col-md-7 mt-2">
                <b-tabs content-class="mt-4" align="center">
                    <b-tab title="Servicios añadidos" active>
                     <div class="table-responsive">
                        <table class="table table-hover table-striped mt-3" >
                            <thead class="bg-secondary text-white">
                            <tr>
                                <th scope="col">Servicio</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora incial</th>
                                <th scope="col">Hora final</th>
                                <th scope="col">Permitido</th>
                                <th scope="col" style="text-center">Costo</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="det in arrayDetalle" :key="det.id">
                                <td>{{det.servicio}}</td>
                                <td>{{det.fecha}}</td>
                                <td>{{det.hora_inicio}}</td>
                                <td>{{det.hora_fin}}</td>
                                <td>{{det.permitido}}</td>
                                <td>${{formatPrice(det.costo)}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><strong>Total</strong></td>
                                <td> ${{formatPrice(totalNeto)}}</td>
                            </tr>
                            </tbody>
                        </table>
                     </div>
                    </b-tab>
                </b-tabs>
            </div> -->
              </div>
        </div>
    </b-modal>


    <b-modal id="presup" title="Motivo de cancelación" hide-footer   >
      <div class="row justify-content-center">
        <div class="col-md-8 mt-3">
            <label for="" class="text-secondary">Motivo</label>
             <select class="form-control" v-model="motivo" >
                <option value="" selected disabled >Seleccione</option>
                <option value="Zona sin Cobertura" >Zona sin Cobertura</option>
                <option value="Falta de Ítem" >Falta de Ítem</option>
            </select>
            <!-- <hr style="border-top: 5px solid #78F57D !important;  opacity :2;"> -->
            <br>
            <center>
                <b-button   size="sm" class="ml-2" variant="danger" @click="rechazarAct3(cancel)" title="Rechazar" >
                    <i class="fas fa-trash"></i> Rechazar
                </b-button>
            </center>
        </div>
      </div>
    </b-modal>


    <b-modal id="testigo" title="Servicios" hide-footer size="lg" header-close-content="Cerrar">
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12">
               <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
              </div>
              <div class="col-md-12">
                    <center>
                        <p>Sube 2 testigos por servicio.</p>
                        <div class="table-responsive">
                            <table class="table table-hover table-striped mt-3" >
                                <thead class="bg-secondary text-white">
                                <tr>
                                    <th scope="col">Servicio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Hora incial</th>
                                    <th scope="col">Hora final</th>
                                    <th scope="col">Costo</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="det in arrayDetalle" :key="det.id">
                                    <td>{{det.servicio}}</td>
                                    <td>{{det.fecha}}</td>
                                    <td>{{det.hora_inicio}}</td>
                                    <td>{{det.hora_fin}}</td>
                                    <td>{{det.costo}}</td>
                                    <td>
                                      <template v-if="!det.checkin && !det.actividad">
                                        <button   class="btn btn-primary btn-sm " type="button" @click="subirTestigo(det)">
                                           Subir testigos
                                        </button>
                                      </template>
                                      <template v-else>
                                        <v-btn  color="#3ABF30" elevation="11"  raised small  style="text-transform: none; color:#fff;" >
                                            <b>Completo</b> 
                                        </v-btn>   
                                      </template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </center>
                 </div>
                <div class="col-md-12">
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                </div>
            </div>
            <!-- <div class="row justify-content-right">
                <div class="col-md-12">
                 <center>
                    <button   class="btn btn-secondary" type="button" @click="cerrarTest()">
                        Cerrar
                    </button>&nbsp;
                    <button :disabled="loader" class="btn btn-primary " type="submit">
                    <span v-if="!loader">
                        <span> Cargar testigo</span>
                    </span>
                    <span v-else>
                        <b-spinner small></b-spinner> Guardando...
                    </span>
                    </button>
                </center>
                </div>
            </div> -->
        </div>
        <!-- <b-form @submit="store">
            <div class="row justify-content-center">
                <div class="col-md-12">
                 <div class="text-center alert alert-secondary ">
                  <center>
                     <br>
                     <h4><b>Testigos</b></h4>
                     <h4>{{obj.evento}}</h4>
                     <br><br>
                     <div class="row justify-content-center">
                        <div class="col-md-6">
                            <h5><b>Región:</b> {{obj.region}}</h5>
                        </div>
                        <div class="col-md-6">
                            <h5><b>Agencia:</b> {{obj.agencia}}</h5>
                        </div>
                     </div>
                     <br><br>
                    <div class="row justify-content-center">
                       <div class="col-md-11">
                            <file-pond ref="pond"  
                                        label-idle="Subir fotos <br> da clic aquí para subir tus testigos."
                                        v-bind:allow-multiple="true"
                                        accepted-file-types="image/jpeg, image/png, image/jpg">
                                <template v-slot:label>

                                </template>
                            </file-pond>
                            <br>
                            <p class="text-secondary" style="font-size: small;">Ingresa toda la información que se requiere para continuar</p>
                        </div>
                     </div>
                    <br><br>
                  </center>
                  <div class="row justify-content-center">
                    <div class="col-md-1 col-6">
                        <button   class="btn btn-secondary float-center" type="button" @click="cerrarTest()">
                            Cerrar
                        </button>
                    </div>
                    <div class="col-md-3 col-6">
                      <button :disabled="loader" class="btn btn-primary float-center" type="submit">
                        <span v-if="!loader">
                            <span> Cargar testigo</span>
                        </span>
                        <span v-else>
                            <b-spinner small></b-spinner> Guardando...
                        </span>
                      </button>
                    </div>
                  </div>
                </div>                   
                </div>
            </div>
        </b-form> -->
    </b-modal>

    
    <b-modal id="subTest" :title="'Testigos ' + testid.servicio" hide-footer size="lg" header-close-content="Cerrar">
        <b-form @submit="store">
         <div class="container">
            <div class="row justify-content-center mt-4">
                <div class="col-md-6">
                    <v-file-input
                            v-model="check"
                            color="deep-purple accent-4"
                            label="Check in"
                            prepend-icon="mdi-camera"
                            outlined
                        >
                    </v-file-input>
                </div>
                <div class="col-md-6">
                    <v-file-input
                            v-model="actividad"
                            color="deep-purple accent-4"
                            label="Actividad"
                            prepend-icon="mdi-camera"
                            outlined
                        >
                    </v-file-input>
                </div>
                <div class="col-md-12">
                  <center>
                    <br>
                    <button :disabled="loader" class="btn btn-primary float-center" type="submit">
                        <span v-if="!loader">
                            <span> Cargar testigo</span>
                        </span>
                        <span v-else>
                            <b-spinner small></b-spinner> Guardando...
                        </span>
                     </button>
                  </center>
                  <br>
                </div>
            </div>
         </div>
        </b-form>
    </b-modal>


    
    <b-modal id="verTest" :title="'Testigo ' + verTe.servicio" hide-footer size="lg" header-close-content="Cerrar">
         <div class="container">
            <div class="row justify-content-center mt-4">
                <div class="col-md-6">
                    <p>Testigo Check in</p>
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                    <b-img  thumbnail fluid :src="$api.baseUriFiles+'activaciones/'+verTe.checkin"></b-img>
                    <br>
                </div>
                <div class="col-md-6">
                    <p>Testigo en actividad</p>
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                    <b-img  thumbnail fluid :src="$api.baseUriFiles+'activaciones/'+verTe.actividad"></b-img>
                    <br>
                </div>
            </div>
            
         </div>
    </b-modal>

</div>
</template>
<script>
import Swal from 'sweetalert2'
// import { GChart } from 'vue-google-charts'
import apexchart from 'vue-apexcharts'
// Import Vue FilePond
// import vueFilePond from "vue-filepond";

// // Import FilePond styles
// import "filepond/dist/filepond.css";


// Create component
// const FilePond = vueFilePond();
import moment  from 'moment';
import VueHtml2pdf from 'vue-html2pdf'
export default {
 name:"Listado",
 components:{
    // GChart
    apexchart,
    // FilePond,
    VueHtml2pdf
 },
  data() {
    return {
        arrayList:[],
        buscar:"",
        tab:1,
        arrayComent1:[],
        tipo:0,
        arrayComent:[],
        agencia:"",
        region:"",
        arrayDetalle:[],
        presupuesto:"",
        edit:{},
        loader:false,
        loader2:false,
        loader3:false,
        obj:{},
        chartOptions:{},
        chartOptions2:{},
        chartOptions3:{},
        chartOptions4:{},
        chartOptions5:{},
        pre1:0,
        tot1:0,
        dis1:0,
        pre2:0,
        tot2:0,
        dis2:0,
        pre3:0,
        tot3:0,
        dis3:0,
        pre4:0,
        tot4:0,
        dis4:0,
        pdf:0,
        myFiles:[],
        myCoordinates: {
            lat: 0,
            lng: 0
        },
        tienda:{
            lat:0,
            lng:0
        },
        Detalle:[],
        Testigo:[],
        preImp:"",
        totalImp:"",
        gastado:"",
        presu: 0,
        centro:0,
        norte:0,
        occidente:0,
        sur:0,
        arrayGra:[],
        date: new Date(),
        step:1,
        filtro:0,
        f_canal:"",
        f_region:"",
        f_agencia:"",
        f_lider:"",
        f_subdirector:"",
        f_inicio:"",
        f_fin:"",
        isMobile: this.$mobile(),
        check:null,
        actividad:null,
        testid:{},
        verTe:{},
        est_sub:"",
        est_emb:"",
        est_age:"",
        cancel:0,
        motivo:"",
        agenciaGra:"",
        regionGra:"",
        url:""
    }
  },
    filters: {
        fechaA:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYYMMDD')
            }
        }
    },
  computed:{
    user() {
        return this.$store.getters.getInfoUser;
    },
    totalNeto: function() {
      var resultado = 0;
      for (var i = 0; i < this.arrayDetalle.length; i++) {
        resultado = resultado + parseInt(this.arrayDetalle[i].costo);
      }
      return resultado;
    },
    listado(){
        
            if (this.f_canal == "" && this.f_region == "" && this.f_agencia == "" && this.f_lider == "" && this.f_subdirector == "" && this.f_inicio == "" && this.f_fin == "" && this.buscar == ""
            && this.est_sub == ""  && this.est_emb == "" && this.est_age == "") {          
                return this.arrayList;
            }else{
                return   this.arrayList.filter(item => {
                        return   item.canal?.toLowerCase().includes(this.f_canal.toLowerCase()) &&
                                item.region?.toLowerCase().includes(this.f_region.toLowerCase()) &&
                                item.agencia?.toLowerCase().includes(this.f_agencia.toLowerCase()) && 
                                item.mrt?.toLowerCase().includes(this.f_lider.toLowerCase()) && 
                                item.subdirector?.toLowerCase().includes(this.f_subdirector.toLowerCase()) && 
                                item.fecha_inicial?.toLowerCase().includes(this.f_inicio.toLowerCase()) && 
                                item.fecha_final?.toLowerCase().includes(this.f_fin.toLowerCase()) && 
                                item.idpdv?.toLowerCase().includes(this.buscar.toLowerCase()) &&
                                item.sub?.toString().includes(this.est_sub.toString()) &&
                                item.trade?.toString().includes(this.est_emb.toString()) &&
                                item.agen?.toString().includes(this.est_age.toString()) 
                        ;
                });
            }  
        

    },
    listfilt(){
    
        if (this.f_region == "" && this.f_agencia == "") {          
            return this.arrayGra;
        }else{
            return   this.arrayGra.filter(item => {
                    return item.region?.toLowerCase().includes(this.f_region.toLowerCase()) &&
                            item.agencia?.toLowerCase().includes(this.f_agencia.toLowerCase())
                    ;
            });
        }  
        

    },
    series(){
       return  [{
            data: [this.pre1,  this.dis1 , this.tot1 ]
        }]
     },
    series2(){
       return  [{
            data: [this.pre2, this.dis2 , this.tot2 ]
        }]
     },
     series3(){
       return  [{
            data: [this.pre3, this.dis3 , this.tot3 ]
        }]
     },
    series4(){
       return  [{
            data: [this.pre4, this.dis4  , this.tot4]
        }]
     }
  },
  methods: {
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
    formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(",", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    grafico1(){
          this.chartOptions = {
                        chart: {
                        type: 'bar',
                        height: 250
                        },
                        plotOptions: {
                        bar: {
                            barHeight: '60%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                             position: 'bottom'
                            },
                        }
                        },
                        colors: ['#A5AFB4', '#8ae442', '#003650'],
                        dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#fff']
                        },
                        formatter: function (val) {
                           let val2 = (val / 1).toFixed(2).replace(",", ",")
                             val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            return "$ " + val2
                        },
                        offsetX: 0,
                        },
                        stroke: {
                        width: 1,
                        colors: ['#fff']
                        },
                        xaxis: {
                          categories: ['Presupuesto inicial', 'Debitado', 'Disponible'
                        ],
                        },
                        yaxis: {
                        labels: {
                            show: false
                        }
                        },
                        tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return '$'
                            }
                            }
                        }
                        },
                         title: {
                            text: '',
                            floating: false,
                            offsetY: 10,
                            align: 'center',
                            style: {
                                color: '#444'
                            }
                            }
                    };

        
    },
    grafico2(){
          this.chartOptions2 = {
                        chart: {
                        type: 'bar',
                        height: 250
                        },
                        plotOptions: {
                        bar: {
                            barHeight: '60%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                             position: 'bottom'
                            },
                        }
                        },
                        colors: ['#A5AFB4', '#8ae442', '#003650'],
                        dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#FFF']
                        },
                        formatter: function (val) {
                           let val2 = (val / 1).toFixed(2).replace(",", ",")
                             val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            return "$ " + val2
                        },
                        offsetX: 0,
                        },
                        stroke: {
                        width: 1,
                        colors: ['#fff']
                        },
                        xaxis: {
                          categories: ['Presupuesto inicial', 'Debitado', 'Disponible'
                        ],
                        },
                        yaxis: {
                        labels: {
                            show: false
                        }
                        },
                        tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return '$'
                            }
                            }
                        }
                        },
                         title: {
                            text: '',
                            floating: false,
                            offsetY: 10,
                            align: 'center',
                            style: {
                                color: '#444'
                            }
                            }
                    };

        
    },
    grafico3(){
          this.chartOptions3 = {
                        chart: {
                        type: 'bar',
                        height: 250
                        },
                        plotOptions: {
                        bar: {
                            barHeight: '60%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                             position: 'bottom'
                            },
                        }
                        },
                        colors: ['#A5AFB4', '#8ae442', '#003650'],
                        dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#FFF']
                        },
                        formatter: function (val) {
                           let val2 = (val / 1).toFixed(2).replace(",", ",")
                             val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            return "$ " + val2
                        },
                        offsetX: 0,
                        },
                        stroke: {
                        width: 1,
                        colors: ['#fff']
                        },
                        xaxis: {
                          categories: ['Presupuesto inicial', 'Debitado', 'Disponible'
                        ],
                        },
                        yaxis: {
                        labels: {
                            show: false
                        }
                        },
                        tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return '$'
                            }
                            }
                        }
                        },
                         title: {
                            text: '',
                            floating: false,
                            offsetY: 10,
                            align: 'center',
                            style: {
                                color: '#444'
                            }
                            }
                    };

        
    },
    grafico4(){
          this.chartOptions4 = {
                        chart: {
                        type: 'bar',
                        height: 250
                        },
                        plotOptions: {
                        bar: {
                            barHeight: '60%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                             position: 'bottom'
                            },
                        }
                        },
                        colors: ['#A5AFB4', '#8ae442', '#003650'],
                        dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#FFF']
                        },
                        formatter: function (val) {
                           let val2 = (val / 1).toFixed(2).replace(",", ",")
                             val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            return "$ " + val2
                        },
                        offsetX: 0,
                        },
                        stroke: {
                        width: 1,
                        colors: ['#fff']
                        },
                        xaxis: {
                          categories: ['Presupuesto inicial', 'Debitado', 'Disponible'
                        ],
                        },
                        yaxis: {
                        labels: {
                            show: false
                        }
                        },
                        tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                            formatter: function () {
                                return '$'
                            }
                            }
                        }
                        },
                         title: {
                            text: '',
                            floating: false,
                            offsetY: 10,
                            align: 'center',
                            style: {
                                color: '#444'
                            }
                            }
                    };

        
    },
    grafico5(){
     this.chartOptions5 =  {
            chart: {
                type: 'donut',
                width: '100%',
                height: 300
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                customScale: 0.8,
                donut: {
                    size: '65%',
                },
                offsetY: 20,
                },
                stroke: {
                colors: undefined
                }
            },
            colors: ['#A5AFB4', '#8ae442', '#003650','#00a1f8'],
            title: {
                text: '',
                style: {
                fontSize: '18px'
                }
            },
            series: [this.centro, this.occidente, this.norte, this.sur],
            labels: ['CENTRO', 'OCCIDENTE', 'NORTE', 'SUR'],
            legend: {
                position: 'left',
                offsetY: 80
            }
        };

        
    },
    cambiaTab(id){
      this.tab = id;
    },
    listadoAct(){
        if (this.user.perfiles[0].usuario_perfil_id == 13 || this.user.perfiles[0].usuario_perfil_id == 5)
        {
            this.url = 'activaciones/indexLider?mrt='+ this.user.mrt;
        }else if(this.user.perfiles[0].usuario_perfil_id == 2){
            this.url = 'activaciones/indexSub?mrt='+ this.user.mrt;
        }else if(this.user.perfiles[0].usuario_perfil_id == 16){
            this.url = 'activaciones/indexAgen?agencia='+ this.user.activa;
        }else{
            this.url ='activaciones/index?id='+ this.user.perfiles[0].usuario_perfil_id;
        }

        this.$api.get(this.url).then(
            ({data}) => {
                this.arrayList = data.data.list;
            }
        ); 
    },
    getComent(){
      this.$api.get("activaciones/getcomentarios/" + this.idedit + "/Terminales Portafolio").then(
                ({data}) => {                 
                    this.arrayComent1 = data.data.comentario;
                    this.coment1 = 1;
                }
            );
    },
    abrirDetalle(item){
        var url= 'activaciones/detalle?id='+ item.id;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.obj = item;
                        this.agencia = item.agencia;
                        this.region = item.region
                        this.arrayDetalle = data.data.detalle;
                        this.presupuesto = data.data.presupuesto;
                        // this.pdf = 1;
                        this.$bvModal.show('detalleAgen');
                    }
                );
    },
    abrirEstatus(item){
        console.log(item);
        this.edit = item;
        this.$bvModal.show('estatusAgent');
    },
    autorizarAct1(id){
        Swal.fire({
            title: '¿Estás seguro de autorizar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#5cb617',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Autorizar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/activar?id='+  id + '&est='+ 1;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación autorizada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    rechazarAct1(id){
        Swal.fire({
            title: '¿Estás seguro de rechazar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#7B2AB4',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Rechazar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/rechazar?id='+  id + '&est='+ 1;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación rechazada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    autorizarAct2(id){
        Swal.fire({
            title: '¿Estás seguro de autorizar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#5cb617',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Autorizar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/activar?id='+  id + '&est='+ 2;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación autorizada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    rechazarAct2(id){
        Swal.fire({
            title: '¿Estás seguro de rechazar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#7B2AB4',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Rechazar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/rechazar?id='+ id + '&est='+ 2;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación rechazada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    autorizarAct3(id){
        Swal.fire({
            title: '¿Estás seguro de autorizar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#5cb617',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Autorizar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/activar?id='+  id + '&est='+ 3;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación autorizada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    modalPorque(id){
        this.cancel = id;
        this.$bvModal.show('presup');
    },
    rechazarAct3(id){
        if (!this.motivo) {
            Swal.fire({
                icon: 'error',
                title: 'Motivo Cancelación',
                text: 'ingrese el motivo para poder rechazar',
                })
        }else{
            Swal.fire({
                title: '¿Estás seguro de rechazar esta activación?',
                showCancelButton: true,
                confirmButtonColor: '#7B2AB4',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Rechazar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    var url= 'activaciones/rechazar?id='+ id + '&est='+ 3 + '&motivo=' + this.motivo;
                    this.$api.get(url).then(
                            ({data}) => {
                                console.log(data);
                                this.editid = "";
                                this.cancel = "";
                                this.$bvModal.hide('presup');
                                this.listadoAct();
                                this.$bvModal.hide('detalleAgen');
                                Swal.fire(
                                    "List@",
                                    "Activación rechazada exitosamente.",
                                    'success'
                                    )
                            }
                        );             
                }
            })
        }
    },
    reenviarCo(id){
        Swal.fire({
            title: '¿Estás seguro de reenviar esta activación?',
            showCancelButton: true,
            confirmButtonColor: '#5cb617',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Autorizar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                var url= 'activaciones/reenviar?id=' +  id;
                this.$api.get(url).then(
                        ({data}) => {
                            console.log(data);
                            this.editid = "";
                            this.listadoAct();
                            this.$bvModal.hide('detalleAgen');
                              Swal.fire(
                                "List@",
                                "Activación reenviada exitosamente.",
                                'success'
                                )
                        }
                    );             
            }
        })
    },
    generaReport(){
        // this.edit = item;
        this.$bvModal.show('report');
    },
    verPresup(){
        var url= 'activaciones/grafico?agencia='+ this.f_agencia + '&region='+ this.f_region;
        this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.pre1 = data.data.pre1;
                    this.pre2 = data.data.pre2;
                    this.pre3 = data.data.pre3;
                    this.pre4 = data.data.pre4;
                    this.tot1 = data.data.total1;
                    this.tot2 = data.data.total2;
                    this.tot3 = data.data.total3;
                    this.tot4 = data.data.total4;
                    this.dis1 = data.data.desponible1;
                    this.dis2 = data.data.desponible2;
                    this.dis3 = data.data.desponible3;
                    this.dis4 = data.data.desponible4;
                    this.centro = data.data.centro;
                    this.norte = data.data.norte;
                    this.occidente = data.data.occidente;
                    this.sur = data.data.sur;
                    this.arrayGra = data.data.detalle;
                    this.agenciaGra = this.f_agencia;
                    this.regionGra = this.f_region;
                        //  this.$bvModal.show('presup');
                    this.presu = 1;
                        setTimeout(() => {
                            this.grafico1();
                            this.grafico2();
                            this.grafico3();
                            this.grafico4();
                            this.grafico5();
                        }, 500); 
                }
            );

    },
    reportExcel(){
        Swal.fire({
            title: '¿Descargar reporte de activaciones?',
            showCancelButton: true,
            confirmButtonColor: '#5cb617',
            cancelButtonColor: '#187abe',
            confirmButtonText: 'Descargar',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed)
            {
                this.loader = true;
                    this.$fileDownload(
                        this.$api.baseUri + 'activaciones/reportes/excel',
                        "reporte_activaciones" + this.$getDate() + ".xlsx",
                    ).then(
                        () => {
                            this.loader = false;
                        }
                    )          
            }
        })   
    },
    finalizarAct(item){
       var url= 'activaciones/detalle?id='+ item.id;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.obj = item;
                        this.agencia = item.agencia;
                        this.region = item.region
                        this.arrayDetalle = data.data.detalle;
                        this.presupuesto = data.data.presupuesto;
                        // this.pdf = 1;
                        this.$bvModal.show('testigo');
                    }
                );
    },
    cerrarTest(){
        this.$bvModal.hide('testigo');
    },
    store(e){
        e.preventDefault();
        this.loader = true;
            
            // // trae localizacion de punto de venta
            // this.$api.get("admin/testigos/localizacion/" + this.obj.idpdv).then(
            //       ({data}) => {
            //           this.tienda.lat = parseFloat(data.data.lat);
            //           this.tienda.lng = parseFloat(data.data.lng);
            //         }
            //     );
            // // trae mi localizacion
            // this.$getLocation({})
            //     .then(coordinates => {
            //         this.myCoordinates = coordinates;
            //     })
            //     .catch(error => alert(error)); 

            //      setTimeout(() => {

            //       Number.prototype.toRad = function() {
            //           return this * Math.PI / 180;
            //         }

            //         var lat2 = this.myCoordinates.lat; 
            //         var lon2 = this.myCoordinates.lng; 
            //         var lat1 = this.tienda.lat; 
            //         var lon1 = this.tienda.lng; 

            //         var R = 6378137; // mts 
            //         //has a problem with the .toRad() method below.
            //         var x1 = lat2-lat1;
            //         var dLat = x1.toRad();  
            //         var x2 = lon2-lon1;
            //         var dLon = x2.toRad();  
            //         var a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
            //                         Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * 
            //                         Math.sin(dLon/2) * Math.sin(dLon/2);  
            //         var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            //         var d = R * c;   

            //       if (d < 500 )
            //         {
         
                    if (!this.check && !this.actividad) {
                        this.loader = false;
                            Swal.fire({
                                icon: 'error',
                                title: 'Testigos incompletos',
                                text: 'Ingresa los testigos en cada servicio.'
                            })
                    }else{

                            let model = this.prepareModel();
                            this.$api.post("activaciones/finalizar", model).then(
                                response => {
                                    this.loader = false;
                                
                                        Swal.fire(
                                        "List@",
                                        "Activación finalizada exitosamente.",
                                        'success'
                                        )
                                        
                                        this.listadoAct();
                                        // this.vaciarDatos();
                                        this.actualizaTest();
                                        this.$bvModal.hide('subTest');
                                        
                                        console.log(response)
                                },
                                error => {
                                    this.loader = false;
                                    if (error.api) {
                                        this.errors = error.api;
                                    }
                                }
                            )
                    }

                

            //  }else{
            //     this.loader = false;
            //     Swal.fire({
            //                 icon: 'error',
            //                 title: 'No te encuestras a menos de 500 metros de la tienda',
            //                 text: 'Validación de geolocalización.'
            //             })
            //  }
        // }, 800)
    },
    prepareModel() {
             var model = new FormData();
                model.append("id", this.testid.id);
                model.append("idact", this.testid.activacionid);
                model.append("check", this.check);
                model.append("actividad", this.actividad);
                
                return model;
    },
    actualizaTest(){
       var url= 'activaciones/detalle?id='+ this.obj.id;
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayDetalle = data.data.detalle;
                    }
                );
    },
    vaciarDatos(){
        this.obj = {};
        this.check = [];
        this.actividad = [];
        this.$bvModal.hide('testigo');
        this.$bvModal.hide('detalleAgen');
    },
    abrirImprimir(item){
        this.obj = item;
        var url= 'activaciones/imprimir?id='+ this.obj.id;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);
                        this.Detalle = data.data.detalle;
                        this.Testigo = data.data.testigo;
                        this.preImp = data.data.pre;
                        this.totalImp = data.data.total;
                        this.gastado = data.data.gastado;
                        this.pdf = 1;
                        setTimeout(() => {
                                this.generateReport();
                            }, 1000)    
                    }
                );

  
    },
    generateReport () {
        this.$refs.html2Pdf.generatePdf()
    },
    subirTestigo(item){
        this.testid = item;
        this.check = null;
        this.actividad = null;
        this.$bvModal.show('subTest');
    },
    verTestigo(item){
        this.verTe = item;
        this.$bvModal.show('verTest');
    },
    reportePdf(){
        Swal.fire({
                title: '¿Descargar reporte fotográfico?',
                showCancelButton: true,
                confirmButtonColor: '#5cb617',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Continuar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                 this.loader3 = true;
                    this.$fileDownload(
                        this.$api.baseUri + 'activaciones/reportes/pdf', 
                         "Reporte_fotografico_Activaciones" + this.$getDate() + ".pdf",
                    ).then(
                        () => {
                            this.loader3 = false;
                        }
                    )           
                }
            })
    }
  },
  mounted(){
    this.listadoAct();
  }
}

</script>
<style scoped>
  #hover:hover{
    background:#B2DAFF;
  }

  #tabsCapturaPop .active {
    background: none;
    color: var(--color-primary);
    transition: all 300ms ease;
  }

  #tabsCapturaPop a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }
  #tabsCapturaPop a i {
    transition: all 300ms ease;
    animation-duration: 0.3s;
    font-size: 10px;
  }

  #tabsCapturaPop #capturaBtnReporte .active {
    background: #00a9e0;
    color: white;
  }

  #tabsCapturaPop #capturaBtnReporte {
    margin: 0 20px 0 20px !important;
  }
  
  .input-nav-search::placeholder {
  color: #a8a8a8;
  font-weight: lighter;
  font-family: TelefonicaLight, serif;
  font-size: 15px;
}


  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


.filepond--drop-label {
     background-color: #00a8f1 !important;
    box-shadow: 0px 10px 10px -6px black;
    cursor: pointer;
    color: #fff;
}

.flexbox {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}

</style>
