<template>
  <div>
    <section class="mt-5">
        <b-carousel :interval="4000"
                    background="#ababab">
          <b-carousel-slide :img-src="isMobile ? 'https://activaciones.asistentemovistar.com.mx/banner_movil.jpg' : 'https://activaciones.asistentemovistar.com.mx/banner.jpg'">
          </b-carousel-slide>
        </b-carousel>
    </section>
    <br><br>
    <div class="row ">
        <div class="col-md-8 col-2"></div>
        <div class="col-md-4 col-8" v-if="user.perfiles[0].usuario_perfil_id != 16">
          <template v-if="user.perfiles[0].usuario_perfil_id == 13">
            <v-btn class="ma-2 ml-3" :color="but == 1 ? '#039CF3' : '#ACACAC'"  @click="butAccion(1)" style="color:#fff; text-transform: none;">
                <i class="far fa-plus"></i> Nueva Activación 
            </v-btn>
            <v-btn class="ma-2"  :color="but == 2 ? '#039CF3' : '#ACACAC'" @click="butAccion(2)" style="color:#fff; text-transform: none;" >
                <i class="far fa-list"></i> Activaciones                
            </v-btn>
          </template>
          <template v-else>
            <v-btn class="ma-2 ml-3" :color="but == 1 ? '#039CF3' : '#ACACAC'"  @click="butAccion(1)" style="color:#fff; text-transform: none;">
                <i class="far fa-list"></i> Activaciones
            </v-btn>
            <v-btn class="ma-2"  :color="but == 2 ? '#039CF3' : '#ACACAC'" @click="butAccion(2)" style="color:#fff; text-transform: none;" >
               <i class="far fa-plus"></i> Nueva Activación 
            </v-btn>
            <!-- <button :class="but == 2 ? 'btn btn-success btn-block ml-3' : 'btn btn-primary btn-block ml-3' " @click="butAccion(2)">
                <i class="far fa-list"></i> Activaciones
            </button> -->
          </template>
        </div>
    </div>
    <template v-if="but == 1 ">
        <template v-if="user.perfiles[0].usuario_perfil_id == 13">
            <Registro></Registro>
        </template>
        <template v-else>
            <Listado ></Listado>
        </template>
        
    </template>
    <template v-else-if="user.perfiles[0].usuario_perfil_id != 16">
        <template v-if="user.perfiles[0].usuario_perfil_id == 13">
            <Listado ></Listado>
        </template>
        <template v-else>
            <Registro></Registro>
        </template>        
    </template>

  </div>
</template>
<script>
import Registro from "./Registro.vue"
import Listado from "./Listado.vue"
export default {
    name:"home",
    components:{
        Registro,
        Listado
    },
    data() {
        return {
          errors:[],
          loader:false,
          but:1,
          isMobile: this.$mobile()
        }
    },
    methods:{
        butAccion(id){
            this.but = id;
        }
    },
    computed:{
        user() {
            return this.$store.getters.getInfoUser;
        },
    },
    created(){
        this.$bus.$on("menuAct", () => {
            this.but = 2;
        });
     },
}
</script>

<style>

</style>